import React, { useRef, useState } from 'react'
import "./Navbar.css"
import AnchorLink from "react-anchor-link-smooth-scroll"
const Navbar = () => {
  const [menu,setMenu]=useState("home")
  const menuRef=useRef()
  const openMenu=()=>{
menuRef.current.style.right="-10px"
  }
  const closeMenu=()=>{
menuRef.current.style.right = "-350px";
  }
  return (
    <div className="flex items-center py-2 justify-between mx-10 md:my-5 md:mx-16 lg:mx-32 navbar">
      <h1>FIRAOL</h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8 mob-open"
        onClick={openMenu}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
      <ul
        ref={menuRef}
        className="flex items-center gap-16 md:gap-8 lg:gap-16 text-xl md:text-base lg:text-lg xl:text-xl navbar-menu"
      >
        <p onClick={closeMenu} className="mob-close">
          x
        </p>

        <li
          className="cursor-pointer flex flex-col md:gap-1"
          onClick={() => setMenu("home")}
        >
          <AnchorLink className="anchor-link" href="#home">
            <p>Home</p>
            {menu === "home" ? <hr /> : <></>}
          </AnchorLink>
        </li>
        <li
          className="cursor-pointer flex flex-col md:gap-1"
          onClick={() => setMenu("about")}
        >
          <AnchorLink className="anchor-link" offset={50} href="#about">
            <p>About Me</p>
            {menu === "about" ? <hr /> : <></>}
          </AnchorLink>
        </li>
        <li
          className="cursor-pointer flex flex-col md:gap-1"
          onClick={() => setMenu("service")}
        >
          <AnchorLink className="anchor-link" offset={50} href="#service">
            <p>Service</p>
            {menu === "service" ? <hr /> : <></>}
          </AnchorLink>
        </li>
        <li
          className="cursor-pointer flex flex-col md:gap-1"
          onClick={() => setMenu("work")}
        >
          <AnchorLink className="anchor-link" offset={50} href="#work">
            <p>Portifolio</p>
            {menu === "work" ? <hr /> : <></>}
          </AnchorLink>
        </li>
        <li
          className="cursor-pointer flex flex-col md:gap-1"
          onClick={() => setMenu("contact")}
        >
          <AnchorLink className="anchor-link" offset={50} href="#contact">
            <p>Contact</p>
            {menu === "contact" ? <hr /> : <></>}
          </AnchorLink>
        </li>
      </ul>
      <a href="#contact">
        <div className="text-black bg-white rounded-2xl items-center px-10 py-2 text-base lg:text-xl contact-me">
          <h1>Contact Me</h1>
        </div>
      </a>
    </div>
  );
}

export default Navbar
