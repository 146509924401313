import React from 'react'
import "./Service.css"
import arrow from "./../../assets/arrow1.jpg"
const Service = () => {
  return (
    <div
      id="service"
      className="flex flex-col items-start md:items-center justify-center gap-12 my-5 mx-10 md:my-16 lg:mx-32 services"
    >
      <div className="relative flex flex-col items-center justify-center gap-1 title">
        <h1 className="p-0 md:px-8 text-5xl md:text-7xl  font-bold">
          Services
        </h1>
        <hr />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-10 mb-20 scontainer ">
        <div className="flex flex-col gap-5 justify-center p-8 md:p-14 lg:p-20 w-[100%] rounded-md sformat">
          <h3 className="text-lg md:text-xl lg:text-2xl font-bold">
            Web Design
          </h3>
          <p className="leading-6 md:leading-8 ">
            Web design is the process of designing appealing user interface
          </p>
          <div className="flex gap-5 items-center mt-5">
            <p>Read more</p>
            <img src={arrow} className="bg-transparent w-5 md:w-6" alt="" />
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-center p-8 md:p-14 lg:p-20 w-[100%] rounded-md sformat">
          <h3 className="text-lg md:text-xl lg:text-2xl font-bold">
            Web Devlopment
          </h3>
          <p className="leading-6 md:leading-8">
            Web design is the process of designing appealing user interface
          </p>
          <div className="flex gap-5 items-center mt-5">
            <p>Read more</p>
            <img src={arrow} alt="" className="bg-transparent w-5 md:w-6" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service
